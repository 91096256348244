//
// sidebar component
//

.sidebar {
  margin-left: - $sm-width;
  transition: margin 0.15s ease-out;
  @include media-breakpoint-up('md') {
    margin-left: 0;
  }

  &-toggle {
    margin-left: 0;
  }

  &-toggle-visible {
    margin-left: 0;
    @include media-breakpoint-up('md') {
      margin-left: - $sm-width;
    }
  }

  &-rounded-top-right {
    @include border-top-right-radius($border-radius);
  }
}

.sidebar-menu {
  width: $sm-width;

  &-fixed {
    position: fixed;
  }
}
